
import firebase from "../firebaseApp";
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import 'animate.css';
  export default {
    name: "SignInThroughEmail",
    mixins: [],
    props: {
      email: null
    },
    data() {
      return {
        emailSend: true,
        auth: getAuth(firebase.app()),
      };
    },
    mounted() {
      if(this.email) {
        this.sendSignInLink()
      }
    },
    methods: {
      async sendSignInLink() {
        const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        if (!emailReg.test(this.email) ? true : false) {
          this.$q.notify({
            color: "negative",
            message: "Vul een geldig email in.",
          });
        } else {
          window.localStorage.setItem('emailForSignIn', this.email)
          const actionCodeSettings = {
            url: 'https://yellowq.app/login',
            // url: 'http://localhost:8080/login',
            handleCodeInApp: true,
          };
          sendSignInLinkToEmail(this.auth, this.email, actionCodeSettings).then(() => {
            this.emailSend = true
          }).catch((error) => {
            console.error(error)
          });
        }
      },
    }
  };
